<template>
  <div class="w-full min-h-screen bg-gray-50 p-6">
    <div class="bg-white rounded-lg shadow-sm">
      <!-- Header Section -->
      <div class="p-4 border-b border-gray-100">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-3">
            <i class="text-gray-600 text-xl" :class="currentProcess?.icon"></i>
            <h2 class="text-lg font-semibold text-gray-800">{{ currentProcess?.name }}</h2>
          </div>
          
          <!-- Filters Section -->
          <div class="flex items-center space-x-4">
            <div class="flex items-center px-4 py-2 bg-gray-50 rounded-lg">
              <!-- Reset Filters with simple icon -->
              <a href="" @click.prevent="resetFilters" class="relative mr-4">
                <i class="fa fa-filter text-sm"
                  :class="{ 'text-blue-500': filtersEnabled, 'text-gray-400': !filtersEnabled }">
                </i>
                <div v-if="filtersEnabled" class="absolute -bottom-1 -right-1 w-2 h-2 bg-red-500 rounded-full"></div>
              </a>

              <!-- Status Filter -->
              <label class="flex items-center space-x-2 border-r pr-4">
                <span class="text-sm text-gray-600">Statusi</span>
                <select 
                  class="text-sm bg-white rounded border border-gray-200 px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                  v-model="useProcessStore().documents_filter_status"
                  @change="useProcessStore().filter_documents(currentProcess.guid)">
                  <option value="">Te gjitha</option>
                  <option value="1">Perfunduara</option>
                  <option value="0">Aktive</option>
                </select>
              </label>

              <!-- Month Filter -->
              <label class="flex items-center space-x-2 border-r px-4">
                <span class="text-sm text-gray-600">Muaji</span>
                <select 
                  class="text-sm bg-white rounded border border-gray-200 px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                  v-model="useProcessStore().documents_filter_month"
                  @change="useProcessStore().filter_documents(currentProcess.guid)">
                  <option value="0">Te gjitha</option>
                  <option v-for="month in useProcessStore().months" :key="month.id" :value="month.id">
                    {{ month.name }}
                  </option>
                </select>
              </label>

              <!-- Search -->
              <div class="flex items-center pl-4">
                <input 
                  type="text" 
                  class="text-sm bg-white rounded border border-gray-200 px-3 py-1 pr-8 w-48 focus:ring-2 focus:ring-blue-500 focus:border-blue-500" 
                  placeholder="Kerko..." 
                  v-model="useProcessStore().documents_filter_text"
                  @keyup="useProcessStore().filter_documents(currentProcess.guid)">
                <i class="fa fa-search fa-xs -ml-7 text-gray-400"></i>
              </div>
            </div>

            <!-- Create Button -->
            <button 
              @click.prevent="create_document()"
              class="flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 border border-blue-200 rounded-lg hover:bg-blue-100 transition-colors duration-200">
              <i class="fa fa-plus mr-2 fa-xs"></i>
              KRIJO
            </button>
          </div>
        </div>
      </div>

      <!-- Table Section -->
      <div class="flex flex-col flex-1">
        <div class="overflow-x-auto">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-50 border-b border-gray-200">
                <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-16">#</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statusi</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div class="flex items-center justify-between gap-2">
                    <span v-if="useProcessStore().process_documents[0]?.origin_type">
                      {{ getPartyName(useProcessStore().process_documents[0]?.origin_type) }}
                      <i class="fa fa-arrow-right text-gray-400 ml-1"></i>
                    </span>
                    <span v-if="useProcessStore().process_documents[0]?.destination_type">
                      <i class="fa fa-arrow-right text-gray-400 mr-1"></i>
                      {{ getPartyName(useProcessStore().process_documents[0]?.destination_type) }}
                    </span>
                  </div>
                </th>
                <th class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Vlera</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Krijuar</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kompletuar</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kerkese per cmim</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="document in useProcessStore().process_documents
                .filter(document => JSON.stringify(document).toLowerCase().includes(useProcessStore().documents_filter_text.toLowerCase()))
                .slice((current_page - 1) * items_per_page, current_page * items_per_page)"
                :key="document.guid"
                @click.prevent="toRoute(document)"
                class="hover:bg-gray-50 transition-colors cursor-pointer"
                :class="{
                  'bg-green-50': document.completed == 1 && !document.with_rejection,
                  'bg-red-50': document.with_rejection,
                  'bg-amber-100': document.discount_status_id == 1
                }">
                
                <td class="px-4 py-3 text-center text-sm text-gray-600 whitespace-nowrap">
                  {{ document.process_number }}
                </td>

                <td class="px-4 py-3 whitespace-nowrap">
                  <span v-if="document.completed == 0" class="text-blue-600">{{ document.step_name }}</span>
                  <div v-else-if="document.with_rejection" class="text-center text-red-600">
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                  <div v-else class="text-center text-green-600">
                    <i class="fa fa-check"></i>
                  </div>
                </td>

                <td class="px-4 py-3">
                  <div class="flex items-center justify-between gap-2">
                    <div :class="{ 'text-red-600': document.origin_type === null || document.origin === null }">
                      <span v-if="document.origin_type == 1">{{ document.pos_origin_name }}</span>
                      <span v-if="document.origin_type == 2">{{ document.warehouse_origin_name }}</span>
                      <span v-if="document.origin_type == 4">{{ document.customer_origin_name }}</span>
                    </div>

                    <div :class="{ 'text-red-600': document.destination_type === null || document.destination === null }">
                      <span v-if="document.destination_type == 1">{{ document.pos_destination_name }}</span>
                      <span v-if="document.destination_type == 2">{{ document.warehouse_destination_name }}</span>
                      <span v-if="document.destination_type == 4">{{ document.customer_destination_name }}</span>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-3 text-right whitespace-nowrap">
                  {{ numeral(document.total_price).format("0,0") }}
                </td>

                <td class="px-4 py-3">
                  <div class="flex justify-between items-center">
                    <div class="text-xs uppercase">
                      <div class="flex items-center text-gray-600">
                        <i class="fa fa-user mr-1 fa-xs text-gray-400"></i>
                        {{ document.user_name }}
                      </div>
                      <div class="flex items-center text-gray-500 mt-1">
                        <i class="fa fa-clock mr-1 fa-xs text-gray-400"></i>
                        {{ useProcessStore().date_filter(document.created_at) }}
                      </div>
                    </div>
                    <div class="text-xs uppercase text-gray-500">
                      {{ months[document.month_created - 1] }}
                    </div>
                  </div>
                </td>

                <td class="px-4 py-3">
                  <div class="flex items-center space-x-2">
                    <div class="flex-shrink-0">
                      <i v-if="document.completed == 1 && !document.with_rejection" 
                         class="fa fa-check text-green-500"></i>
                      <i v-if="document.completed == 0" 
                         class="fa fa-times text-red-500"></i>
                    </div>

                    <div v-if="document.completed == 1" class="text-xs uppercase">
                      <div class="flex items-center text-gray-600">
                        <i class="fa fa-user mr-1 text-gray-400"></i>
                        {{ document.user_completed_name }}
                      </div>
                      <div class="flex items-center text-gray-500 mt-1">
                        <i class="fa fa-clock mr-1 text-gray-400"></i>
                        {{ useProcessStore().date_filter(document.closed_at) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-3">
                  <div v-if="document.discount_status_id"
                    :class="{
                      'bg-green-50 p-2 rounded': document.discount_status_id == 2,
                      'bg-red-50 p-2 rounded': document.discount_status_id == 3 || document.discount_status_id == 4,
                    }"
                    class="flex items-center justify-between">

                    <div class="text-xs uppercase" style="width:45%;">
                      <div v-if="document.discount_status_id == 1 || 
                        document.discount_status_id == 2 || 
                        document.discount_status_id == 3">
                        <div class="flex items-center text-gray-600">
                          <i class="fa fa-user mr-1 fa-xs text-gray-400"></i>
                          {{ document.discount_request_by_name }}
                        </div>
                        <div class="flex items-center text-gray-500 mt-1">
                          <i class="fa fa-clock mr-1 fa-xs text-gray-400"></i>
                          {{ useProcessStore().date_filter(document.discount_request_created_at) }}
                        </div>
                      </div>
                    </div>

                    <div class="text-center" style="width:10%;">
                      <i v-if="document.discount_status_id == 1"
                        class="fa-solid fa-hourglass-half text-amber-500"></i>
                      <i v-if="document.discount_status_id == 2"
                        class="fa-solid fa-check text-green-500"></i>
                      <i v-if="document.discount_status_id == 3"
                        class="fa-solid fa-xmark text-red-500"></i>
                      <span v-if="document.discount_status_id == 4" 
                        class="text-xs text-gray-600">Anulluar nga</span>
                    </div>

                    <div class="text-xs uppercase text-right" style="width:45%;">
                      <div v-if="document.discount_status_id == 1" class="flex justify-end">
                        <div class="px-2 py-1 border border-gray-300 rounded text-gray-600">
                          {{ document.discount_request_assigned_to_name }}
                          <i class="fa fa-user ml-1 fa-xs text-gray-400"></i>
                        </div>
                      </div>
                      <div v-if="document.discount_status_id == 2 || document.discount_status_id == 3">
                        <div class="flex items-center justify-end text-gray-600">
                          {{ document.discount_request_by_name }}
                          <i class="fa fa-user ml-1 fa-xs text-gray-400"></i>
                        </div>
                        <div class="flex items-center justify-end text-gray-500 mt-1">
                          {{ useProcessStore().date_filter(document.discount_request_status_updated_at) }}
                          <i class="fa fa-clock ml-1 fa-xs text-gray-400"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Pagination -->
        <div class="p-4 border-t border-gray-200">
          <Dapaginator 
            :totalPages="total_pages" 
            :currentPage="current_page" 
            @changePage="change_page"
            class="flex justify-center">
          </Dapaginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useProcessStore } from '@/stores/process'
var numeral = require("numeral");
import Dapaginator from '@/components/Dapaginator.vue'
 

function toRoute(document) {
  window.location.href = '/process/document/' + document.process_id + '/' + document.guid
}

function getPartyName(party) {
  switch (party) {
    case 1:
      return 'POS'
    case 2:
      return 'Magazine'
    case 3:
      return 'Furnitor'
    case 4:
      return 'Klient'
    default:
      return ''
  }
}

function resetFilters() {
  useProcessStore().documents_filter_status = ''
  useProcessStore().documents_filter_month = 0
  useProcessStore().documents_filter_text = ''
  useProcessStore().get_process_documents(useProcessStore().process_documents[0]?.process_id)
}

</script>
<script>
export default {
  data() {
    return {
      current_page: 1,
      items_per_page: 12,
      months: [
        "Janar",
        "Shkurt",
        "Mars",
        "Prill",
        "Maj",
        "Qershor",
        "Korrik",
        "Gusht",
        "Shtator",
        "Tetor",
        "Nentor",
        "Dhjetor",
      ]

    }
  },
  methods: {
    change_page(page) {
      this.current_page = page
    },
    create_document() {
      // Go to create route od current 
      window.location.href = '/process/' + this.$route.params.process_id + '/new'
    },
    getProcessName() {
      return useProcessStore().processes_list.find(process => process.guid == this.$route.params.process_id)?.name
    }

  },
  computed: {
    currentProcess() {
      return useProcessStore().processes_list.find(process => process.guid == this.$route.params.process_id)
    },
    total_pages() {
      return Math.ceil(useProcessStore().process_documents.length / this.items_per_page)
    },
    filtersEnabled() {
      return useProcessStore().documents_filter_status != '' || useProcessStore().documents_filter_month != '' || useProcessStore().documents_filter_text != ''
    }
  },
  watch: {
    '$route.params.process_id'(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Fetch the process documents for the new process ID
        useProcessStore().get_process_documents(newValue);
      }
    },
  },
}
</script>

<style scoped>
td {
  vertical-align: middle;
}

td:first-child {
  width: 0px;
  color: #4a5568;
  font-size: 0.8rem;
}

.filters {
  margin: auto;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  padding: 0.05rem;
}

label>span {
  font-size: 0.8rem;
  color: #4a5568;
  padding-right: 0.25rem;
  border-right: 1px solid #e2e8f0;
}

.table-container {
  flex-grow: 1;
}

.pagination {
  margin-top: 10px;
}

tbody {
  flex-grow: 1;
  align-items: center;
}

.table-wrapper {
  height: calc(100vh - 140px);
}
</style>
